// Service Widget
((document) => {
  const oReq = new XMLHttpRequest();
  const findADealerURL = window.location.href.indexOf('/find-a-dealer/');
  const fordURL = window.location.pathname.includes('ford-');
  const isServiceBookingLandingPage =
    window.location.pathname === '/service-repairs/car-servicing/';
  const serviceForm = document.querySelectorAll('.js-service-banner__form');

  const manufacturerLinkContainer = document.querySelector(
    '.js-service-banner__manufacturer-link-container',
  );
  const manufacturerLink = document.querySelector('.js-service-banner__manufacturer-link');
  const { sendToManufacturersList } = window;
  const { pathname } = window.location;
  const getManufacturerInPathname = sendToManufacturersList
    ? sendToManufacturersList.find((item) => pathname.includes(item.name))
    : undefined;
  const IS_SERIVICE_FORM_BUT_NOT_MANUFACTURERS_LIST =
    serviceForm.length > 0 && !getManufacturerInPathname;

  // Ford vehicles
  function findFordVehicle(register) {
    const fordID = document.querySelector('.fordID');
    oReq.open('GET', `/content/api/json_veh.p?regno=${register}`);
    oReq.responseType = 'json';
    oReq.onreadystatechange = function stateChange() {
      if (oReq.response) {
        if (fordID && oReq.response.make === 'Ford') {
          const { id } = fordID.id;
          window.location.href = `https://www.ford.co.uk/owner/my-vehicle/book-a-service#!/vehicle-step?dc=${id}`;
        } else {
          const { pathname } = window.location;
          const dealershipName = pathname.slice(
            pathname.indexOf('/find-a-dealer/') + '/find-a-dealer/'.length,
            -1,
          );
          const SERVICE_URL = '/service-repairs/car-servicing/book-online/';
          const FAD_SERVICE_URL = `/find-a-dealer/${dealershipName}/car-servicing/`;
          window.location.href = `${dealershipName ? FAD_SERVICE_URL : SERVICE_URL}`;
        }
      }
    };
    oReq.send();
  }

  function scrollToBookingForm() {
    const serviceBookingForm = document.getElementById('serviceBooking');
    const formClientBoundingRect = serviceBookingForm?.getBoundingClientRect();
    const formTop = formClientBoundingRect.y;
    const windowY = window.scrollY;
    const siteNavContainer = document.querySelector('.site-nav-container');
    const offset = siteNavContainer ? siteNavContainer.clientHeight : 100;

    window.scrollTo({
      top: formTop + windowY - offset,
      behavior: 'smooth',
    });
  }

  // None Ford vehicles
  function findVehicle() {
    const serviceUrlStructure = '/service-repairs/car-servicing/book-online';
    const isMotorcycleAndFindADealer =
      findADealerURL > -1 && window.location.href.includes('motorcycle');
    const isFindADealerURL = findADealerURL > -1;
    const isServiceBookingFormOnPage = document.getElementById('serviceBooking') !== null;
    const isServiceUrlStructureOnPage = typeof window.serviceUrlStructure !== 'undefined';

    switch (true) {
      case isMotorcycleAndFindADealer:
        window.location.href = 'motorcycle-servicing/';
        break;
      case isFindADealerURL:
        window.location.href = 'car-servicing/';
        break;
      case isServiceBookingFormOnPage:
        scrollToBookingForm();
        break;
      case isServiceUrlStructureOnPage:
        window.serviceUrlStructure = serviceUrlStructure;
        window.location.href = window.serviceUrlStructure;
        break;
      case isServiceBookingLandingPage:
        window.serviceUrlStructure = serviceUrlStructure;
        break;
      default:
        console.error('No Service Booking URL or form was found');
        break;
    }
  }

  // If buy-or-sell homepage widget is present
  function findVehicleSell() {
    window.location.href = window.sellMyCarUrlStructure;
  }

  function showForms(form) {
    if (form.classList.contains('hide__default')) {
      form.classList.remove('hide__default');
    }
  }

  // Set the session storage from the button click
  if (IS_SERIVICE_FORM_BUT_NOT_MANUFACTURERS_LIST) {
    serviceForm.forEach((form) => {
      showForms(form);

      form.addEventListener('submit', (event) => {
        const q57 = form.querySelector('#Q57').value;
        sessionStorage.setItem('car reg', q57);
        if (findADealerURL && fordURL) {
          findFordVehicle(q57);
        } else if (
          form.classList.contains('js-service-banner__form--sell-my-car') ||
          form.parentElement.parentElement.classList.contains('js-buy-sell')
        ) {
          findVehicleSell();
        } else {
          findVehicle();
        }
        event.preventDefault();
      });
    });
  } else if (manufacturerLink && manufacturerLinkContainer) {
    manufacturerLink.href = getManufacturerInPathname.link;
    manufacturerLinkContainer.classList.remove('hide__default');
  } else {
    serviceForm.forEach((form) => {
      showForms(form);
      throw new Error('Not able to find the manufacturer link. Defaulting to service form');
    });
  }
})(document);
